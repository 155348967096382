@media screen and (max-width: 991px) {
    .cols {
        display: flex; 
        flex-direction: column;
      }

      .ml-1{
        margin-left: 0px;
      }
      
      .ml-2{
        margin-left: 0px;
      }

      .ml-3{
        margin-left: 0px;
      }
    
      .stacked_fields {
        margin-right: 0px;
      }
    
    .form_text_field {
        width: 100%;
        }

    .double_width_field {
        width: 100%;
    }
    .xsmall_width_field {
        width: 100%
    }

    .stateZip {
        margin-left: 0px;
    
        }

        .checkbox_group{
            flex-direction: column;
            margin-left: 16px;
            
          }
          .form_section_container{
            padding: 16px
          }
.ack_center{
  text-align: center;
}
          .acknowledgement_verbaige{
            font-size: 14px;
            line-height: 16px;
            text-align: center;
          }

          .other_group{
            display: flex;
            flex-direction: column;
            width: 100%;

          }
          .submit_button_container{
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .submit_application_button{
            font-size: 14px;
            padding: 8px;
            width:200px;
          }
        
          .submit_application_button:hover{
            background-color: var(--dk_purple);
            cursor: pointer;
        
          }
          .contact_card_container {
            width: 730px;
            max-width: 950px;
          }
        

}
