.error_icon{
    color: white;
    font-size: 25px;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 16px;
}

@media screen and (max-width: 479px) {
    .error_icon{
    width: 60px;
    height: 60px;
    padding-top: 14px;
    padding-left: 10px;
    }
    }