@media screen and (max-width: 479px) {
  .form_section_title {
    font-size: 15px;
}
  .form_section_container {
    margin: 20px 0px;
  }
    .form_label{
      font-size: 14px;
  } 

    .form_text_field {
      font-size: 14px;
      }

  .small_checkbox_group{
    flex-direction: column;
    padding-left: 16px;
  }

  .contact_card_container {
    width: auto;
    min-width: 320px;
  }


}