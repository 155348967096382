@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');



body{
--lt_purple: #64f;
--md_purple: #5542b9;
--md_purple: #6350cbfc;
--dk_purple: #4330a7;
--off_white: #f0f4f9;
--smoke: #f3f3f3;
--lt_gray: #f8fafc;
--md_gray: #ccc;
--dk_gray: #484b4e;
--pink: #f9d;
/* --pink: rgb(196, 195, 195); */
--black: #000;
--primary_font:'Open Sans', sans-serif; 


}

.shadow {
  box-shadow: 6px 6px 8px 0 var(--black);
}

.colorPink {
  color: var(--pink);
}

/* QuickChange of theme components */
body {
  --header_background: var(--lt_gray)

}

body {
  font-family: var(--primary_font);
  color: var(--black);
  font-size: 14px;
  line-height: 20px;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: var(--md_gray);
  border-bottom-color: var(--md_gray);
  border-left-color: var(--md_gray);
  border-right-color: var(--md_gray);
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px var(--md_gray);
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: var(--md_gray);
  border-bottom-color: var(--md_gray);
  border-left-color: var(--md_gray);
  border-right-color: var(--md_gray);
}







.body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dk_purple);
  font-family: var(--primary_font);
  padding-bottom: 100px;
}

.body.bg_purple {
  background-color: var(--dk_purple);
}

a{
  color: var(--pink);
}

.captcha_error{
  color: red;
  font-weight: bold;
  padding: 20px 0px;
}

.mobile_menu_icon {
  display: none;
}

.public_hero_section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(248, 250, 252, 0.74)), to(rgba(248, 250, 252, 0.74))), url('../media/background.jpg');
  background-image: linear-gradient(180deg, rgba(248, 250, 252, 0.74), rgba(248, 250, 252, 0.74)), url('../media/background.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 750px;
  background-repeat: repeat, repeat;
}

.public_hero_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1240px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.public_hero_left_column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.offline_application_instructions{
  font-size: 16px;
  line-height: 22px;
  color: var(--smoke);
  text-align: center;
}
.offline_form_section_title{
  font-size: 22px;
  line-height: 28px;
  color: var(--md_gray);
  text-align: center;

}

.offline_tech_problems_request_text{
  color: var(--smoke);
  font-size: 16px;
  margin-bottom: 20px;
}

.public_hero_text_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.public_hero_headline {
  color: var(--dk_purple);
  text-align: center;
  text-transform: uppercase;
}

.public_hero_subtext {
  max-width: 80%;
  color: var(--lt_purple);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.button {
  padding: 16px 0px;
  border-radius: 8px;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  border: 0;
  text-decoration: none;
  border-radius: 8px;
  text-align: center;
  border-radius: 5px;
}

.darkButton {
  background-color: var(--dk_purple);
  color: var(--smoke);
  font-size: 14px;

}

.darkButton:hover {
  background-color: var(--lt_purple);
}

.lightButton {
  background-color: var(--md_gray);
  color: var(--dk_purple);

}

.lightButton:hover {
  background-color: var(--lt_gray);
}

.heroButtonContainer{
  margin-top: 20px;
}

.public_hero_left_column{
  width: 50%;
}

.public_hero_right_column{
  width: 50%;
}

.public_hero_image_container{
  width: 100%;

}



.public_donate_section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1240px;
  min-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--pink);
}

.public_donate_container {
  max-width: 1240px;
  padding-bottom: 20px;
}

.public_donate_left_column {
  width: 50%;
}

.public_donate_right_column {
  width: 50%;
}

.public_donate_left_column_image_container{
  width: 100%
}

.public_donate_columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.public_donate_right_column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.public_donate_column_text_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.public_donate_headline {
  color: var(--dk_purple);
  font-size: 34px;
  text-align: center;
  text-transform: uppercase;
}

.heading {
  color: var(--lt_purple);
}

.public_donate_text {
  padding: 0px 20px;
  color: var(--dk_purple);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
}

.donate_link{
  color: var(--dk_purple);
  font-weight: bold;
}

.public_donate_button {
  margin-top: 10px;
  font-weight: 700;
}

.public_why_adopt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1240px;
  min-width: 100%;
  padding: 60px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dk_purple);
}

.public_why_adopt_background {
  max-width: 1240px;
  padding: 40px;
  border-radius: 20px;
  background-color: var(--md_purple);
}

.public_why_text_container {
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.public_why_headline {
  color: var(--off_white);
  font-size: 34px;
}

.public_why_list_item {
  margin-bottom: 10px;
  color: var(--off_white);
  font-size: 16px;
  line-height: 24px;
}



.text-block {
  font-size: 11px;
}

.public_why_subtitle {
  color: var(--pink);
  font-size: 12px;
}

.public_why_headline_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-span {
  color: var(--pink);
}

.public_why_list_headline {
  color: var(--pink);
  font-weight: 700;
}

.public_hero_subtext_bold {
  color: var(--dk_purple);
  font-size: 20px;
  font-weight: 700;
}

.available_body_container {
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dk_purple);
}

.available_headline_section {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: var(--dk_purple);
}

.available_text_section {
  max-width: 991px;
  background-color: var(--dk_purple);
  padding: 0px 20px;
}

.available_cards_section {
  background-color: var(--dk_purple);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;

}

.available_cards_gallery_container {
  display: -ms-grid;
  display: grid;
  width: 991px;
  padding: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: var(--dk_purple);
}

.available_card_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 300px;
  height: 550px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: var(--md_purple);
  box-shadow: 6px 6px 8px 0 var(--black);
  color: var(--off_white);
  border-radius: 5px;
}

.available_image_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.available_card_text_container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.available_card_title_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.available_card_button_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
}

.availBtn {
  margin: 5px 0px;
}

.available_image {
  overflow: hidden;
  width: 90%;
  height: 290px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.available_card_title {
  color: var(--off_white);
  font-size: 24px;
  font-weight: 700;
  -o-object-fit: fill;
  object-fit: fill;
}

.available_text {
  color: var(--pink);
  font-size: 16px;
  text-align: center;
}

.availalable_buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--lt_purple);
  color: var(--off_white);
  font-weight: 700;
}

.availalable_buttons:hover {
  background-color: var(--dk_gray);
}



.available_subtitle {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--pink);
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.available_headline {
  color: var(--pink);
  font-size: 38px;
  line-height: 44px;
  text-align: center;
}

.available_content_container {
  max-width: 1240px;
}

.payment_section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dk_purple);
}

.payment_container {
  max-width: 1240px;
  min-width: 992px;
  color: transparent;
}

.payment_form_outer_container {
  padding: 40px;
  background-color: var(--dk_purple);
  color: var(--dk_purple);
}

.payment_form_label {
  color: var(--dk_purple);
}

.payment_form_text_input.mobile_small_input {
  max-width: 200px;
}

.payment_form_input_container.small_input {
  max-width: 30%;
}

.payment_form_input_container.medium_input {
  width: 600px;
}

.payment_form_input_container.donation_amount_input {
  width: 150px;
}

.submit-button {
  background-color: var(--pink);
}

.payment_form_inner_container {
  padding: 40px;
  border-radius: 16px;
  background-color: var(--off_white);
  color: var(--off_white);
}

.payment_heading_text {
  color: var(--lt_purple);
}

.payment_details_text {
  color: var(--dk_purple);
}

.radio-button-label {
  color: var(--dk_purple);
  font-weight: 700;
}

.payment_card_type_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.payment_form_small_input_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.payment_location_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.payment_form_button_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.reset_payment_button {
  margin-right: 10px;
  background-color: var(--lt_purple);
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.alert_body {
  position: absolute;
  z-index: 1;
  background-color: rgba(72, 75, 78, 0.80);
}

.alert_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.alert_card {
  width: 400px;
  height: 250px;
  border-radius: 16px;
  background-color: var(--off_white);
}

.alert_icon_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--dk_purple);
}

.alert_button_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.alert_message {
  padding: 20px 10px;
  font-family: var(--primary_font);
  font-size: 18px;
  text-align: center;
}

.alert_dismiss_button {
  background-color: var(--pink);
  color: black;
  font-family: var(--primary_font);
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--lt_purple);
  color: var(--lt_gray);
}

.form_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  max-width: 1240px;
  min-width: 991px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block {
  width: 100%;
}





.submit-button-2 {
  background-color: var(--dk_purple);
}

.application_disclaimer_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1240px;
  min-width: 991px;
  margin-top: 40px;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--dk_purple);
  color: var(--off_white);
}

.application_disclaimer_title_container {
  background-color: var(--pink);
}

.application_disclaimer_body_container {
  margin-top: 20px;
}

.application_disclaimer_captcha_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.application_disclaimer_button_container {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.application_disclaimer_title {
  color: var(--dk_purple);
  text-align: center;
}

.disclaimer_list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.captcha {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 75px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--off_white);
  color: var(--lt_purple);
  font-weight: 700;
}

.agree_button {
  padding-right: 20px;
  padding-left: 20px;
  background-color: var(--pink);
  color: var(--md_purple);
}

.dont_agree_button {
  background-color: var(--lt_purple);
}


.paragraph {
  color: var(--off_white);
}

.block-quote {
  color: var(--off_white);
  font-style: italic;
}



.adoption_page_container {
  display: flex;
  max-width: 1240px;
  min-width: 991px;
  padding-top: 40px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
}

.adoption_process_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.adoption_application_issues_container {
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

.adoption_page_background {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: var(--dk_purple);
}

.adoption_fee_container{
  width: 100%;
 }


.adoption_process_card {

  width: 100%;
  margin-top: 40px;
  padding: 20px 40px 20px 44px;
  border-radius: 11px;
  background-color: var(--md_purple);
  box-shadow: 6px 6px 3px 0 var(--black);
}

.adoption_fee_schedule_container{
  width: 50%;
  padding: 20px;
}

.adoption_fee_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px 20px;
  border-radius: 8px;
  background-color: var(--md_purple);
  box-shadow: 3px 3px 3px 0 var(--black);
}

.adoption_process_title {
  color: var(--pink);
}

.adoption_process_list_item {
  color: var(--lt_gray);
  font-size: 16px;
  line-height: 24px;
}

.adoption_process_sub_list {
  list-style-type: lower-roman;
}



.adoption_fee_header_text {
  color: var(--pink);
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
}

.adoption_fee_schedule {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-areas: "Area";
  -ms-grid-columns: 2.75fr 8px 1fr;
  grid-template-columns: 2.75fr 1fr;
  -ms-grid-rows: auto 8px auto;
  grid-template-rows: auto auto;
}

.adoption_fee_item {
  font-size: 18px;
  line-height: 24px;
  color: var(--pink);
}

.adoption_fee_price {
  color: var(--pink);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.foster_application_button_card{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.foster_apply_button_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1240px;
}
.foster_downoad_button_container{
  display:flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;

}
.foster_download_title_container{
  text-align: center;
}




.adoption_application_issues_title {
  color: var(--off_white);
  font-size: 18px;
}

.adoption_application_issue_text {
  color: var(--off_white);
  text-align: center;
}
.adoption_upper_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.adoption_lower_container {
  display: flex;
  width: 800px;
  flex-direction: column;
  align-items: center;
}

.adoption_apply_button_container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.adopt_button_body{
  background-color: var(--pink);
  border-radius: 5px;
  color: var(--dk_purple);
  font-weight: bold;
  padding: 16px 16px;
  text-decoration: none;
  font-size: 16px;
}

.adopt_button_body:hover {
  background-color: var(--md_purple);
  color: var(--pink);
}

.small_print{
  font-size: 12px;
}

.foster_application_button_card{
  width: 100%;
  max-width: 1240px;
  padding: 20px 40px 20px 44px;
  border-radius: 11px;
  /* background-color: var(--md_purple); */
  /* box-shadow: 6px 6px 3px 0 var(--black); */
}


.foster_application_process_card {
  width: 100%;
  margin-top: 40px;
  padding: 20px 40px 20px 44px;
  border-radius: 11px;
  background-color: var(--md_purple);
  box-shadow: 6px 6px 3px 0 var(--black);
}

.foster_process_container {
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  max-width: 1240px;
}

.foster_process_title {
  color: var(--pink);
}

.foster_process_list_item {
  color: var(--lt_gray);
  font-size: 16px;
  line-height: 24px;
}

.adoption_process_list_item-copy {
  color: var(--lt_gray);
}

.foster_page_background {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dk_purple);
}

.foster_lower_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 800px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.word_application_button {
  background-color: var(--off_white);
  color: #0569ff;
  font-weight: 700;
}

.pdf_download_button {
  margin-left: 20px;
  padding-right: 20px;
  padding-left: 20px;
  color: #ce0c0f;
  font-weight: 700;
}



.paragraph-2 {
  padding: 10px;
}

.about_body_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
background-color: var(--dk_purple);
}

.about_card_container {
  max-width: 1240px;
  min-width: 992px;
  padding: 100px;
  border-radius: 8px;
  background-color: var(--md_purple);
  box-shadow: 6px 6px 6px 0 var(--black);
}

.about_title_text {
  color: var(--pink);
  text-align: center;

}

.about_subheading_text {
  color: var(--lt_gray);
  font-size: 16px;
  line-height: 24px;
}

.donate_body_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dk_purple);
}

.donate_container {
  width: 65%;
  max-width: 1240px;
  background-color: var(--off_white);
  padding: 20px;
}





.adoption_app_title_container{
width: 100%;
max-width: 1240px;
padding: 0 60px;
}

.adoption_app_title{
color: var(--pink);
text-align: center;

}

@media screen and (max-width: 991px) {


  .body {
    font-family: var(--primary_font);
  }

  .menu_link_text {
    font-size: 14px;
    line-height: 20px;
  }

 

  .mobile_menu_icon {
    display: none;
  }

  .public_hero_headline {
    font-size: 28px;
    line-height: 33.6px;
  }

  .public_hero_subtext {
    font-size: 14px;
    line-height: 18px;
  }

  .public_donate_section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .public_donate_left_column {
    max-width: none;
    width: 100%;
  }

  .public_donate_right_column {
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
  }

  .public_donate_text {
    color: var(--dk_gray);
  }

  .public_why_adopt_background {
    padding-right: 25px;
    padding-left: 25px;
  }

  .public_why_text_container {
    padding-right: 10px;
    padding-left: 10px;
  }

 

  .payment_container {
    min-width: 750px;
    padding-left: 0px;
  }

  .payment_form_outer_container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .form_container {
    min-width: 768px;
  }

  .application_disclaimer_container {
    min-width: 766px;
  }

  .adoption_upper_container {
    flex-direction: column;
  }

  .adoption_process_card{
    margin-top: 10px;
   }



  .paragraph {
    font-size: 12px;
  }

  .adoption_page_container {
    min-width: 766px;
  }

  .adoption_process_card {
    width: 100%;
  }

  .adoption_lower_container {
    width: 600px;
  }

  .foster_application_process_card {
    width: 90%;
  }

  .foster_lower_container {
    width: 600px;
  }



  .about_card_container {
    min-width: 768px;
  }

  .donate_container {
    max-width: 950px;
  }

  .available_cards_gallery_container {
    display: grid;
    width: 100%;
    padding: 0px;
    align-content:space-around;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    background-color: var(--dk_purple);
  }
}

@media screen and (max-width: 768px) {


  .public_hero_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .public_hero_headline {
    font-size: 28px;
    line-height: 35px;
  }

  .public_hero_subtext {
    font-size: 15px;
    line-height: 20px;
  }

  .button {
    font-size: 12px;
  }

  .public_donate_section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .public_donate_columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .public_hero_left_column{
    width: 100%;
  }
  
  .public_hero_right_column{
    width: 100%;
  }

  .public_why_adopt {
    padding-right: 20px;
    padding-left: 20px;
  }

  .public_why_adopt_background {
    padding-right: 10px;
    padding-left: 10px;
  }

  .public_why_headline {
    font-size: 30px;
    line-height: 38px;
  }

  .public_why_list_item {
    font-size: 14px;
    line-height: 20px;
  }



  .public_why_subtitle {
    font-size: 12px;
    line-height: 18px;
  }

  .public_why_headline_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .public_hero_subtext_bold {
    line-height: 28px;
  }

  .payment_section {
    min-width: 480px;
  }

  .payment_container {
    min-width: 480px;
  }

  .payment_heading_text {
    font-size: 30px;
    line-height: 36px;
  }

  .form_container {
    min-width: 480px;
  }

  .form-2 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .form-block {
    width: 100vw;
  }

  .application_disclaimer_container {
    min-width: 480px;
  }



  .adoption_page_container {
    min-width: 480px;
  }

  .adoption_process_card {
    width: 450px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .adoption_process_title {
    font-size: 18px;
  }

  .adoption_process_list_item {
    font-size: 12px;
  }

  .adoption_application_issue_text {
    font-size: 12px;
  }

  .adoption_lower_container {
    width: 450px;
  }

  .foster_application_process_card {
    padding-right: 20px;
    padding-left: 20px;
  }

  .foster_process_title {
    font-size: 18px;
  }

  .foster_process_list_item {
    font-size: 12px;
  }

  .adoption_process_list_item-copy {
    font-size: 12px;
  }

  .foster_page_background {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .foster_lower_container {
    width: 450px;
  }



  .about_card_container {
    min-width: 458px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .donate_body_container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .available_headline {
    font-size: 24px;
    line-height: 34px;
  }

  .available_subtitle {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    line-height: 16px;
  }

  .available_image_container {
    width: 300px;
    height: 500px;
  }

  .available_cards_gallery_container {
    display: grid;
    width: 100%;
    padding: 0px 20px;
    align-content:space-around;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background-color: var(--dk_purple);
    
  }


}


@media screen and (max-width: 479px) {

.application_disclaimer_container{
min-width: 320px;
padding: 10px;
}
  .public_hero_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .public_hero_subtext {
    line-height: 20px;
  }

  .button {
    margin-top: 10px;
  }

  .public_why_adopt {
    padding-right: 5px;
    padding-left: 5px;
  }

  .public_why_adopt_background {
    padding-right: 8px;
    padding-left: 8px;
  }

  .public_why_list_item {
    font-size: 12px;
    line-height: 16px;
  }

  .public_donate_right_column {
    padding-right: 20px;
    padding-left: 20px;
  }



  .payment_section {
    overflow: visible;
    max-width: 479px;
    min-width: 320px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .payment_container {
    min-width: 320px;
  }

  .payment_form_outer_container {
    padding: 10px;
  }

  .payment_form_label {
    font-size: 12px;
  }

  .payment_form_inner_container {
    padding: 10px 0px;
  }

  .payment_heading_text {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .payment_details_text {
    font-size: 12px;
    line-height: 16px;
  }

  .radio-button-label {
    font-size: 12px;
  }

  .payment_card_type_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .alert_card {
    width: 320px;
    height: 225px;
  }

  .alert_message {
    font-size: 16px;
  }

  .alert_dismiss_button {
    font-size: 12px;
  }

  .form_container {
    max-width: 479px;
    min-width: 320px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .form-block {
    width: 100vw;
  }
  .adoption_upper_container{
    width:100%;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
  }

  .adoption_page_container {
    min-width: 766px;
  }

  .adoption_process_card {
    width: 100%;
  }

  .adoption_lower_container {
    width: 600px;
  }


.adoption_fee_schedule_container {
  padding: 5px;
}

 .adoption_process_card{
  margin-top: 5px;
 }

 .adoption_fee_item, .adoption_fee_price {
  font-size: 12px;
  line-height: 14px;
}

.adoption_process_container{
  display: block;
  padding-left: 5px;
  padding-right: 5px;
}

.adoption_application_issues_title_container{
  width:100%;
}
.adoption_application_issues_title {
  font-size: 14px;
  text-align: center;
}

  .application_disclaimer_title {
    font-size: 24px;
    line-height: 30px;
  }

  .application_disclaimer_body_healine_text {
    font-size: 12px;
    line-height: 16px;
  }

  .disclaimer_list_item {
    font-size: 12px;
    line-height: 16px;
  }

  .disclaimer_list {
    padding-left: 20px;
  }

  .captcha {
    width: 200px;
    height: 65px;
  }



  .agree_button {
    font-size: 12px;
  }

  .dont_agree_button {
    font-size: 12px;
  }



  .adoption_page_container {
    min-width: 320px;
  }

  .adoption_process_card {
    padding: 5px 10px;
  }

  .adoption_lower_container {
    width: 375px;
  }

  .foster_process_container{
    padding: 5px;
  }

  .foster_application_process_card {
    width: 100%;
    padding: 5px;
  }

  .foster_page_background {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .foster_downoad_button_container{
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  
  }



  .about_body_container {
    height: 64vh;
    padding: 20px;
  }

  .about_card_container {
    min-width: 320px;
  }

  .about_title_text {
    font-size: 28px;
    line-height: 36px;
  }

  .about_subheading_text {
    font-size: 13px;
    line-height: 18px;
  }

  .available_cards_gallery_container {
    display: grid;
    width: 100%;
    padding: 0px 16px ;
    align-content:space-around;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    background-color: var(--dk_purple);
  }

  .available_image_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .adoption_app_title_container{
    width: 100%;
    padding: 8px;
    }
    
    .adoption_app_title{
    color: var(--pink);
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    
    }

  .donate_body_container {
    padding: 10px;
  }

   .donate_container {
    max-width: 479px;
    width: 95%;
   }


}