.mt-1 {
  margin-top: 8px;
}
.mt-2 {
  margin-top: 16px;
}

.ml-1{
  margin-left: 8px;
}

.ml-2{
  margin-left: 16px;
}

.ml-3 {
  margin-left: 24px;
}

.pr-1 {
  padding-right: 8px;
}

.pr-2 {
  padding-right: 16px;
}

.pl-1{
  padding-left: 8px;
}

.pl-2{
  padding-left: 16px;
}

.cols {
  display: flex;
  flex-direction: row;
}

.fullSize {
  width: 100%;
}

.halfSize {
  width: 50%;
}

.form_section_title_fine_print{
  font-size: 14px;
}
.adoption_app_form_container{
    width: 100%;
    max-width: 1240px;
    padding: 0px 16px;
}

.form_section_title {
    font-size: 18px;
    color: var(--pink);
    margin: 10px 0px;
    font-weight: bold;
}



.form_error{
  background-color: red;
  padding: 16px 16px;
  margin-bottom: 8px;
}

.form_error_message {
  color: white;
  font-weight: bold;
  text-align: center;
}
.form_section_container {
  background-color: var(--md_purple);
  padding: 24px;
  margin: 20px;
}
.multiLine_fields {
  display: flex; 
  flex-direction: row;
justify-content: space-between;
}

.closed_space{
  justify-content: left;
}
.stacked_fields {
  display: flex; 
  flex-direction: column;

}

.address {
  justify-content: left;
}

.stateZip {
margin-left: 8px;
}
.contact_subtitle_text{
  color: var(--lt_gray);
  font-size: 16px;
  font-weight: normal;
}

.contact_body_container {
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--dk_purple);
}

.contact_title_text {
  color: var(--pink);
  text-align: center;
}

.contact_card_container {
  width: 940px;
  max-width: 1240px;
  padding: 40px;
  border-radius: 8px;
  background-color: var(--md_purple);
  box-shadow: 6px 6px 8px 0 var(--black);
}

.form_label{
    color: var(--lt_gray);
    font-size: 16px;
    font-weight: normal;
} 

.form_text_field {
width: 100%;
padding-left: 5px;
margin-bottom: 10px;
height: 40px;
background-color: var(--smoke);
font-size: 16px;
}

.double_width_field {
  width: 100%;
}

.xsmall_width_field {
  width: 150px;
}

 
  .form_select_field {
    background-color: var(--smoke);
    height: 40px;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .form_textarea {
    background-color: var(--smoke);
    width: 100%;
    margin-bottom: 10px;
  }

  .checkbox_group_label{
    color: var(--lt_gray);
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 16px;
  }

  .checkbox_group{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  .small_checkbox_group{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 50%;
  }


  .checkbox_label {
    color: var(--lt_gray);
    font-weight: 700;
    font-size: 16px;
  }
  

  .w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px;
  }
  
  .w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
  }
  
  .w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    grid-row-start: 1;
    clear: both;
  }
  
  .w-checkbox-input {
    float: left;
    margin-bottom: 0px;
    margin-left: -20px;
    margin-right: 0px;
    margin-top: 4px;
    line-height: normal;
  }

  .w-checkbox-input :checked{
accent-color: red;
  }
  
  .w-checkbox-input--inputType-custom {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-color: var(--md_gray);
    border-bottom-color: var(--md_gray);
    border-left-color: var(--md_gray);
    border-right-color: var(--md_gray);
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  input[type='checkbox'] {
accent-color: var(--pink);
}
  
  .w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: var(--md_gray);
    border-top-color: var(--md_gray);
    border-bottom-color: var(--md_gray);
    border-left-color: var(--md_gray);
    border-right-color: var(--md_gray);
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
   }
  
  .w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0px 0px 3px 1px var(--md_gray);
  }

  .pet_title {
    color: var(--smoke);
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .pet_input_container{
    margin-top: 8px;
    margin-bottom: 16px;
    border: dashed var(--smoke) 1px;
    padding: 8px;
  }

  .other_group {
    width: 60%
  }

  .acknowledgement_verbaige{
    color: var(--smoke);
    font-size: 16px;
    line-height: 21px;
  }

  .submit_application_button{
    background-color: var(--pink);
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    width: 200px;
    border-radius: 6px;
  }

  .submit_application_button:hover{
    background-color: var(--smoke);
    cursor: pointer;

  }

  .submit_button_container{
    margin-top: 24px;
  }

  /* .dummy-positioning {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  } */
  
 
  .success-icon {
    display: inline-block;
    width: 3.5em;
    height: 3.5em;
    font-size: 20px;
    border-radius: 50%;
    border: 4px solid #4cc93f;
    background-color: #fff;
    position: relative;
    overflow: hidden;
  }
  
  .success-icon__long {
      display: block;
      position: absolute;
      height: 4px;
      background-color:#4cc93f;
      border-radius: 10px;
      width: 40px;
      transform: rotate(-50deg);
      top: 26px;
      left: 22px;
    }
  
   .success-icon__tip {
    display: block;
    position: absolute;
    background-color:#4cc93f;
    border-radius: 10px;
      height: 4px;
      width: 25px;
      top: 34px;
      left: 8px;
       transform: rotate(40deg);
    } 
  

  
  

  




  