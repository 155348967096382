
.footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--dk_gray);
  }
  


.footer_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1240px;
    min-width: 991px;
    padding: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }


.footer_left_container {
    padding-right: 60px;
    padding-left: 10px;
  }
  
  .footer_right_container {
    display: flex;
    padding:0 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


.footer_company_name {
    color: var(--pink);
  }

.footer_text {
    color: var(--pink);
    font-size: 14px;
    font-style: italic;
    text-align: center;
  }

  @media screen and (max-width: 991px) {

    .footer_container {
        min-width: 768px;
        padding-bottom: 40px;
      }
    
      .footer_company_name {
        font-size: 18px;
      }
      .footer_right_container {
        padding:0 80px;

      }

  }




  @media screen and (max-width: 767px) {
  .footer_container {
    min-width: 480px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer_left_container {
    padding-right: 10px;
  }

  .footer_text {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 479px) {
    .footer_container {
        min-width: 320px;
      }
    .footer_right_container {
      padding: 0px;

    }
    }