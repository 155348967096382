
.body.epay {
    padding: 40px;
  }
  
  .epay_logo_image {
    width: 200px;
    height: 150px;
  }
  
  
  .epay_container {
    display: flex;
    max-width: 1250px;
    min-width: 950px;
    padding: 20px 10px 50px 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .payment_details_form_container {
    min-width: 80%;
  
  }
  
  .epay_form_field {
    color: #022450;
    font-weight: bold;
  }
  
  .epay_form_Select_input{
    display: block;
    height: 38px;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
  }
  
  .epay_form_Text_input {
    display: block;
    height: 32px;
    padding: 8px 12px;
    margin-bottom: 10px;
    line-height: 1.428571429;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid #cccccc;
  }
  
  .epay_form_TextArea_input{
    font-size: 14px;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
    padding: 8px 12px;
  }
  
  .epay_title_container {
    padding-right: 170px;
    padding-left: 170px;
  }
  
  .epay_title_text {
    margin-bottom: 0px;
    text-align: center;
  }
  
  .epay_sub_title_text {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  
  .epay_instruction_text {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }
  
  .epay_payment_details_form {
    display: flex;
    flex-direction: column;
  
  }
  
  .epay_amounts_title_text{
    font-size: 20px;
  }
  
  .epay_amount_details {
    display: flex;
    min-width: 80%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid black;
  }
  
  .epay_amounts_left {
    padding-right: 20px;
  }
  
  .epay_amounts_right {
    padding-right: 0px;
    padding-left: 20px;
  }
  
  .epay_amount_table {
    display: flex;
    margin-bottom: 12px;
  }
  
  .epay_amount_table_text {
    font-size: 20px;
    line-height: 24px;
    text-align: right;
  }
  
  
  .checkout_button_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .epay_trans_id {
    margin-top: 10px;
    font-size: 14px;
  }
  
  /* Buttons and links */
  .checkout_button {
    background-color: var(--dk_purple);
    color: white;
    padding: 12px;
    border-radius: 5px;
    font-weight: bold;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  }
  
  
  .Another_Charge_Button {
    background-color: #022450;
    color: white;
    padding: 12px;
    border-radius: 5px;
    font-weight: bold;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  }
  
  .form_error_message {
    color: white;
    font-weight: bold;
  }
  
  
  
  @media screen and (max-width: 1920px) {
    .body.epay {
      font-family: 'Droid Sans', sans-serif;
    }
  
      .epay_container {
      min-width: 725px;
    }
  
    .epay_title_container {
      padding-right: 90px;
      padding-left: 90px;
    }
  
  
    .epay_amount_table_text {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 1420px) {
    .epay_title_container {
      padding-right: 30px;
      padding-left: 30px;
    }
  
    .epay_instruction_text {
      font-size: 14px;
      line-height: 18px;
    }
  }
  
  @media screen and (max-width: 767px) {
  .epay_container {
      min-width: 480px;
    }
  
    .payment_details_form_container {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .epay_title_container {
      padding-right: 30px;
      padding-left: 30px;
    }
  
    .epay_title_text {
      font-size: 28px;
      line-height: 31px;
    }
  
    .epay_sub_title_text {
      font-size: 18px;
    }
  
    .epay_instruction_text {
      font-size: 12px;
      line-height: 15px;
    }
  
    .payment_details_form_container {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      /* width: 80vw; */
      min-width: initial;
    }
  }
  
  @media screen and (max-width: 479px) {
    .epay_logo_image {
      width: 50px;
      height: 75px;
    }

    
  
    .body.epay {
      padding-right: 10px;
      padding-left: 10px;
    }
  
      .epay_title_container {
      padding-right: 8px;
      padding-left: 8px;
    }
  
      .epay_container {
      min-width: 320px;
    }
  
    .epay_title_text {
    font-size: 24px;
    line-height: 28px;
    }

    .epay_amount_table_text{
      font-size:14px;
      line-height: 16px;
    }
    .epay_amount_details {
   padding: 10px;
    }
  
    .epay_trans_id{
      font-size: 12px;
    }
  
  }