.topnav {
    overflow: hidden;
    background-color: var(--dk_purple);
    position: relative;
  }
  
  .topnav a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }
  
.topnav{
width: 100%;
  }

  .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--header_background);
  }
  
  .header_nav_bar_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1240px;
    min-width: 992px;
    padding: 10px 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .header_logo_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 200px;
  }
  
  .header_nav_container {
    width: 50%;
  }
  
  .nav_menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-left: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .menu_link {
    margin-left: 20px;
  }

  .nav_icon_container {
    display: none;
  }



  .header_message_bar {
    display: flex;
    width: 100%;
    min-height: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--dk_purple);
    padding: 5px 0px;
  }

  .header_alert_text_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    min-width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header_social_container {
    display: flex;
    min-width: 10%;
    flex-direction: row;
    justify-content: center;
  }

  .facebook_icon_container {
    display: flex;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  
  
  .header_message_bar_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1240px;
    min-width: 992px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .header_message_bar_container_text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    min-width: 991px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }


@media screen and (max-width: 991px) {

  .header_message_bar_container_text {
    min-width: 768px;
  }



  .header_nav_bar_container {
    width: auto;
    min-width: 768px;
  }

  .header_logo_container {
    max-width: 150px;
  }

  .header_nav_container {
    width: 75%;
  }


  
  .nav_x1 {
    width: 35px;
    height: 5px;
    background-color: var(--pink);
    margin: 6px 0;
    transition: 0.4s;
    transform: translate(0, 11px) rotate(-45deg);
  }
  .nav_x2 {
    width: 35px;
    height: 5px;
    background-color: var(--pink);
    margin: 6px 0;
    transition: 0.4s;
    opacity: 0;
  }
 .nav_x3 {
    width: 35px;
    height: 5px;
    background-color: var(--pink);
    margin: 6px 0;
    transition: 0.4s;
    transform: translate(0, -11px) rotate(45deg);
  }
  .nav_icon1 {
    width: 35px;
    height: 5px;
    background-color: var(--pink);
    margin: 6px 0;
    transition: 0.4s;
  }
  .nav_icon2 {
    width: 35px;
    height: 5px;
    background-color: var(--pink);
    margin: 6px 0;
    transition: 0.4s;
  }
 .nav_icon3 {
    width: 35px;
    height: 5px;
    background-color: var(--pink);
    margin: 6px 0;
    transition: 0.4s;
  }
}


.menu_link_text {
  color: var(--dk_purple);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
}

.menu_link_text:hover {
  color: var(--pink);
  cursor: pointer;
}

.nav_current {
  color:var(--pink) !important;
  text-decoration: underline;

}





.header_donate_link {
  color: var(--pink);
  text-decoration: none;
}

.header_alert_text {
  color: var(--pink);
  font-size: 12px;
  text-decoration: none;
}



@media screen and (max-width: 767px) {

  .header_message_bar_container {
    min-width: 468px;
  }

  .header_message_bar_container_text {
    min-width: 468px;
  }
  .header_nav_bar_container {
    min-width: 100%;
    padding-bottom: 10px;
  }

  .header_nav_container {
    display: none;
  }

  .header_mobile_nav_container {
    width: 50px;
    height: 50px;
  }

  .mobile_menu_icon {
    display: block;
    width: 50px;
    height: 50px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: var(--lt_gray);
  }

  .apply_now_badge {
    display: none;
  }

  .nav_icon_container {
    display: inline-block;
    cursor: pointer;
  }


}
  @media screen and (max-width: 479px) {
    .header {
      width: 90%;
    }

    .header_message_bar_container {
      min-width: 352px;
    }
  
    .header_message_bar_container_text {
      min-width: 352px;
    }

  .header_nav_bar_container {
    padding: 12px;
    min-width: 100%;
    
  }

  .header_logo_container {
    max-width: 100px;
  }


  .header_mobile_nav_container {
    width: 50px;
    height: 50px;
    max-height: none;
    max-width: none;
  }

  .mobile_menu_icon {
    width: 50px;
    height: 50px;
    max-height: none;
    max-width: none;
    padding-right: 0px;
    padding-bottom: 0px;
  }




}